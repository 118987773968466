import { useRef } from 'react';

function Icon({ color }: { color?: string }) {
  return (
    <svg
      className="c-tooltip__icon"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM3.67742 5.25055C3.85206 3.57845 5.26595 2.275 6.98428 2.275H7.50928C9.05567 2.275 10.3093 3.5286 10.3093 5.075C10.3093 6.39617 9.39424 7.50363 8.16335 7.79819C7.99164 7.83928 7.85928 7.98585 7.85928 8.1624V8.75C7.85928 8.9433 7.70258 9.1 7.50928 9.1H6.45928C6.26598 9.1 6.10928 8.9433 6.10928 8.75V7.28823C6.10928 6.6458 6.63007 6.125 7.27251 6.125H7.50928C8.08918 6.125 8.55928 5.6549 8.55928 5.075C8.55928 4.4951 8.08918 4.025 7.50928 4.025H6.98428C6.23375 4.025 5.60583 4.54996 5.44769 5.25273C5.40525 5.44131 5.25258 5.6 5.05928 5.6H4.00928C3.81598 5.6 3.65734 5.4428 3.67742 5.25055ZM6.12502 11.55C6.12502 11.7433 6.28172 11.9 6.47502 11.9H7.52502C7.71832 11.9 7.87502 11.7433 7.87502 11.55V10.5C7.87502 10.3067 7.71832 10.15 7.52502 10.15H6.47502C6.28172 10.15 6.12502 10.3067 6.12502 10.5V11.55Z"
        fill={color ? color : '#BCBFC1'}
      />
    </svg>
  );
}

export function ToolTip({
  children,
  triggerElement,
  color,
  hintClassName,
}: {
  children: React.ReactNode;
  triggerElement?: React.ReactNode;
  color?: string;
  hintClassName?: string;
}) {
  let $target: HTMLDivElement | null = null;
  const ref: any = useRef();

  const handleMouseOut = () => {
    const tooltipHint = document.querySelector('.c-tooltip__hint');
    if (tooltipHint) {
      tooltipHint.remove();
    }
  };

  const handleMouseOver = () => {
    if (!document) return;

    $target = document.createElement('div');
    $target.className = `c-tooltip__hint ${hintClassName}`;
    $target.innerHTML = `<span class="--triangle"></span>${children}`;
    ref.current.append($target);

    const targetOffsetParent = $target.offsetParent as HTMLDivElement;
    const targetParentNode = $target.parentNode as HTMLDivElement;
    const targetTriangle = $target.firstElementChild as HTMLDivElement;

    if ($target) {
      const deltaTop = 5;
      const deltaLeft = 10;

      const top = targetOffsetParent?.offsetHeight + deltaTop;
      let left = targetParentNode.offsetLeft - deltaLeft;
      const triangleLeft =
        targetParentNode.offsetWidth / 2 + targetParentNode.offsetLeft - 7;

      const isTargetLongerThanContainer =
        targetParentNode.offsetWidth +
          targetParentNode.offsetLeft +
          $target.offsetWidth -
          deltaLeft >
        targetOffsetParent.offsetWidth;

      const isTargetLessOrEqualThanParentNode =
        targetParentNode.offsetWidth + targetParentNode.offsetLeft >
          $target.offsetWidth ||
        targetParentNode.offsetWidth + targetParentNode.offsetLeft ===
          $target.offsetWidth;

      if (isTargetLessOrEqualThanParentNode) {
        left = $target.offsetWidth / 2;
        targetTriangle.style.left =
          triangleLeft - $target.offsetWidth / 2 + 'px';
      }

      if (isTargetLongerThanContainer) {
        if (isTargetLessOrEqualThanParentNode) {
          left =
            targetParentNode.offsetWidth +
            targetParentNode.offsetLeft -
            $target.offsetWidth +
            deltaLeft;
          targetTriangle.style.left =
            $target.offsetWidth - 2 * deltaLeft - 4 + 'px';
        } else {
          left = 0;
          targetTriangle.style.left = triangleLeft + 'px';
        }
      }

      $target.style.visibility = 'visible';
      $target.style.left = left + 'px';
      $target.style.top = top + 'px';
      $target.style.zIndex = '7';
    }
  };

  return triggerElement ? (
    <div
      ref={ref}
      className="c-tooltip__icon-wrapper"
      onMouseOver={handleMouseOver}
      onClick={handleMouseOver}
      onMouseOut={handleMouseOut}
      onTouchCancel={handleMouseOut}
    >
      {triggerElement}
    </div>
  ) : (
    <div className="c-tooltip">
      <div
        ref={ref}
        className="c-tooltip__icon-wrapper"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onTouchCancel={handleMouseOut}
      >
        <Icon color={color} />
      </div>
    </div>
  );
}
