import {
  CurrentPricePeriod,
  ProductType,
} from 'src/components/Calculator/types';
import { DataAsYouUseComponentType } from 'src/components/Calculator/types/advanced';

function natSpecificationFull(value: string) {
  if (value === 'NAT Gateway Small') return '10 000 (Small)';
  if (value === 'NAT Gateway Middle') return '50 000 (Middle)';
  if (value === 'NAT Gateway Large') return '200 000 (Large)';
  if (value === 'NAT Gateway Extra large') return '1 000 000 (Extra large)';
  return value;
}

function natSpecificationParts(value: string) {
  if (value === 'NAT Gateway Small') return { label: 'Small', value: '10 000' };
  if (value === 'NAT Gateway Middle')
    return { label: 'Middle', value: '50 000' };
  if (value === 'NAT Gateway Large')
    return { label: 'Large', value: '200 000' };
  if (value === 'NAT Gateway Extra large')
    return { label: 'Extra large', value: '1 000 000' };
  return { label: value, value: value };
}

function eipSpecification(value: string, variant?: 'short') {
  switch (value) {
    case 'velocity':
      return 'Пропуск. способность';
    case 'traffic':
      if (variant === 'short') return 'Количество трафика';
      return 'Количество трафика';
    default:
      return value;
  }
}

function obsStorageClass(value: string) {
  switch (value) {
    case 'Standard':
      return 'Горячее';
    case 'Warm':
      return 'Теплое';
    case 'Cold':
      return 'Холодное';
    default:
      return value;
  }
}

function addVcpuAndRamPrefix(value: string): string {
  const entityNames = ['vCPU', 'ГБ RAM'];
  const result = value
    .split(' ')
    .map((el, ind) => `${el} ${entityNames[ind]}`)
    .join(', ');
  return result;
}

function obsDataRedundancyPolicy(value: string, place: 'results' | 'main') {
  switch (value) {
    case 'Multi':
      if (place === 'results') return 'Три зоны';
      if (place === 'main') return 'В трех зонах';
    case 'Single':
      if (place === 'results') return 'Одна зона';
      if (place === 'main') return 'В одной зоне';
    default:
      return value;
  }
}

function obsRestoringDataType(value: string) {
  switch (value) {
    case 'Standart':
      return 'Стандартное';
    case 'Fast':
      return 'Быстрое';
    default:
      return value;
  }
}

function elbConfigType(value: string, variant?: 'short') {
  switch (value) {
    case 'Shared':
      if (variant === 'short') return 'Общий';
      return 'Общий (бесплатный)';
    case 'Dedicated':
      return 'Выделенный';
    default:
      return value;
  }
}

function elbConfigBalancerWorkLevel(value: string, variant?: 'short') {
  switch (value) {
    case 'HTTP/HTTPS L7':
      if (variant === 'short') return 'Нагрузка приложений';
      return 'Балансировка нагрузки приложений (HTTP/HTTPS)';
    case 'TCP/UDP L4':
      if (variant === 'short') return 'Сетевая нагрузка';
      return 'Балансировка сетевой нагрузки (TCP/UDP)';
    default:
      return value;
  }
}

function renameDiskTypesValues(value: string) {
  switch (value) {
    case 'SAS':
      return 'HDD';
    case 'SSD':
      return 'SSD';
    default:
      return value;
  }
}

function unitByte(value: string) {
  switch (value) {
    case 'gb':
      return 'ГБ';
    case 'tb':
      return 'Тб';
    default:
      return value;
  }
}

function textByGen(value: string) {
  switch (value) {
    case '3':
      return 'Sky Lake';
    case '6':
      return 'Cascade Lake';
    case '7':
      return 'Ice Lake';
    default:
      return '';
  }
}

function localeAllProductTitle(productType: ProductType) {
  switch (productType) {
    case 'cloudServer':
      return 'Виртуальная машина';
    case 'network':
      return 'Сеть';
    case 'elasticLoadBalance':
      return 'Балансировка нагрузки серверов';
    case 'cloudContainerKubernetes':
      return 'Кластеры Kubernetes';
    case 'mySqlDatabase':
      return 'Управление MySQL DB';
    case 'postgreSqlDatabase':
      return 'Управление PostgreSQL DB';
    case 'sqlDatabase':
      return 'Управление SQL DB';
    case 'documentDatabase':
      return 'Управление MongoDB';
    case 'objectStorage':
      return 'Объектное хранилище данных (S3)';
    case 'cloudBackup':
      return 'Резервное копирование данных';
    case 'scalableFile':
      return 'Сервис хранения данных';
    case 'mapReduce':
      return 'Платформа анализа Big Data';
    case 'dataWarehouseService':
      return 'Корпоративное хранилище данных';
    case 'dmsRabbitMq':
      return 'Брокер очередей RabbitMQ';
    case 'dataLakeInsight':
      return 'Обработка больших данных';
    case 'dmsKafka':
      return 'Брокер Apache Kafka';
    case 'functionGraph':
      return 'Serverless functions';
    case 'dcsRedis':
      return 'Сервис кеширования на Redis';
    case 'dcsMemcached':
      return 'Кеширование на базе Memcached';
    case 'cloudSearchService':
      return 'Система поиска Elasticsearch';
    case 'dataAsYouUse':
      return 'Обработка и анализ Big Data';
    //
    case 'virtualDataCenter':
      return 'Облако на базе VMware';
    //
    case 'evolutionCloudServer':
      return 'Виртуальная машина';
    case 'evolutionCloudServerFreeTier':
      return 'Виртуальная машина Free Tier';
    case 'evolutionStorageS3':
      return 'Объектное хранилище S3';
    case 'evolutionStorageS3FreeTier':
      return 'Объектное хранилище S3 Free Tier';
    case 'evolutionKubernetes':
      return 'Управляемые кластеры Kubernetes';
    case 'evolutionPostgreSql':
      return 'Управляемые базы данных PostgreSQL®';
    case 'evolutionBareMetal':
      return 'Выделенный сервер';
    case 'evolutionCloudServerGpu':
      return 'Виртуальные машины с GPU';
    // ai
    case 'summarizer':
      return 'Суммаризатор';
    case 'rewriter':
      return 'Рерайтер';
    case 'anpr':
      return 'Детекция номеров авто';
    case 'textClassification':
      return 'Text Classification';
    case 'ruСlip':
      return 'ruСLIP';
    case 'ghost':
      return 'GHOST';
    case 'kandinsky':
      return 'Kandinsky/Kandinsky 2.0';
    case 'productsAnalyzer':
      return 'Products analyzer';
    case 'mGpt_3':
      return 'mGPT-3';
    case 'rudolph_2_7В':
      return 'RUDOLPH 2.7В';
    case 'ruDallЕ':
      return 'ruDALL-E';
    case 'ruGpt_3':
      return 'ruGPТ-3';
    case 'saluteSpeech':
      return 'SaluteSpeech';
    case 'emptyPage':
      return 'Калькулятор';
    //
    default:
      return productType;
  }
}

function localePricePeriod(pricePeriod: CurrentPricePeriod) {
  const pricePeriodValuesMap = {
    priceHour: 'В час',
    priceDay: 'В день',
    priceMonth: 'В месяц',
  };

  return pricePeriodValuesMap[pricePeriod];
}

function localeProductTitle(productType: ProductType) {
  let title = '';
  title = localeAllProductTitle(productType);

  return title;
}

function localeAllAdProductTitle(productType: ProductType) {
  switch (productType) {
    //
    case 'virtualDataCenter':
      return 'Виртуальный ЦОД';
    //
    case 'evolutionCloudServer':
    case 'evolutionCloudServerFreeTier':
      return 'Evolution Compute';
    case 'evolutionStorageS3':
    case 'evolutionStorageS3FreeTier':
      return 'Evolution Object Storage';
    case 'evolutionKubernetes':
      return 'Evolution Managed Kubernetes';
    case 'evolutionPostgreSql':
      return 'Evolution Managed PostgreSQL®';
    case 'evolutionBareMetal':
      return 'Evolution Bare Metal';
    case 'evolutionCloudServerGpu':
      return 'Evolution Compute GPU';
    //
    case 'cloudServer':
      return 'Elastic Cloud Server';
    case 'network':
      return 'NAT Gateway';
    case 'elasticLoadBalance':
      return 'Elastic Load Balance';
    case 'cloudContainerKubernetes':
      return 'Cloud Container Engine';
    case 'mySqlDatabase':
      return 'Relational Database service for MySQL';
    case 'postgreSqlDatabase':
      return 'Relational Database service for PostgreSQL';
    case 'sqlDatabase':
      return 'Relational Database service for SQL';
    case 'documentDatabase':
      return 'Document Database Service with MongoDB';
    case 'objectStorage':
      return 'Object Storage Service';
    case 'cloudBackup':
      return 'Cloud Backup and Recovery';
    case 'scalableFile':
      return 'Scalable File Service Turbo';
    case 'mapReduce':
      return 'MapReduce Service';
    case 'dataWarehouseService':
      return 'Data Warehouse Service';
    case 'dmsRabbitMq':
      return 'Distributed Message Service for RabbitMQ';
    case 'dataLakeInsight':
      return 'Data Lake Insight';
    case 'dmsKafka':
      return 'Distributed Message Service for Kafka';
    case 'functionGraph':
      return 'Function Graph';
    case 'dcsRedis':
      return 'Distributed Cache Service for Redis';
    case 'dcsMemcached':
      return 'Distributed Cache Service for Memcached';
    case 'cloudSearchService':
      return 'Cloud Search Service';
    case 'dataAsYouUse':
      return 'Data As You Use';
    //
    default:
      return productType;
  }
}

function localeAdProductTitle(productType: ProductType) {
  let title = '';
  title = localeAllAdProductTitle(productType);

  return title;
}

function capitalizeWord(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

function capitalizeDividedWordsInString(str: string, divider: string = '-') {
  const words = str.split(divider);
  return words.map(capitalizeWord).join(divider);
}

function renameDliRequestQueueType(value: string): string {
  switch (value) {
    case 'sql':
      return 'For SQL';
    case 'general-purpose':
      return 'For General Purpose';
    default:
      return value;
  }
}

function renameDliResourceUsageMod(value: string): string {
  switch (value) {
    case 'dedicated':
      return 'Выделенный';
    case 'nonDedicated':
      return 'Невыделенный';
    default:
      return value;
  }
}

function renameDscVersion(value: string): string {
  return `Redis ${value}.0`;
}

function renameDataAsYouUseComponentType(
  value: DataAsYouUseComponentType,
): string {
  switch (value) {
    case 'CDM':
      return 'Cloud Data Migration (CDM)';
    case 'DIS':
      return 'Data Ingestion Service (DIS)';
    default:
      return '';
  }
}

function renameEvolutionStorageS3StorageType(storageType: string) {
  switch (storageType) {
    case 'cold':
      return 'Холодное';
    case 'standart':
      return 'Стандартное';
    case 'ice':
      return 'Ледяное';
    default:
      return storageType;
  }
}

function renameEvoPostgreSqlClusterType(clusterType: string) {
  switch (clusterType) {
    case 'primary/standby':
      return 'Primary/Standby';
    case 'single':
      return 'Single';
    default:
      return clusterType;
  }
}

function renameEvoPostgreSqlDeploymentMode(deploymentMode: string) {
  switch (deploymentMode) {
    case 'standart':
      return 'Стандарт';
    case 'business':
      return 'Бизнес';
    default:
      return deploymentMode;
  }
}

const adapter = {
  capitalizeWord,
  capitalizeDividedWordsInString,
  addVcpuAndRamPrefix,
  renameDiskTypesValues,
  natSpecificationFull,
  natSpecificationParts,
  eipSpecification,
  obsStorageClass,
  obsDataRedundancyPolicy,
  obsRestoringDataType,
  unitByte,
  elbConfigType,
  elbConfigBalancerWorkLevel,
  textByGen,
  localeProductTitle,
  localeAdProductTitle,
  renameDliRequestQueueType,
  renameDliResourceUsageMod,
  renameDscVersion,
  renameDataAsYouUseComponentType,
  renameEvolutionStorageS3StorageType,
  renameEvoPostgreSqlClusterType,
  renameEvoPostgreSqlDeploymentMode,
  localePricePeriod,
};

export { adapter };
