/**
 *
 * Форматирует строки или числа в строки
 *
 * "10000" => "10 000"
 *
 * 7.704000000000001 => 7,70
 *
 * 5546.880000000001 => 5 546,88
 *
 * 50084593.92 => 50 084 593,92
 */
function numberFormat(number: number | string, commaPointer?: number) {
  if (typeof number === 'number')
    number = parseFloat(number.toFixed(commaPointer ?? 2)).toString();
  const numberToShow = number;
  const regex = /(?=\B(?:\d{3})+(?!\d))/g;
  return numberToShow.replace(regex, ' ').replace('.', ',');
}

export { numberFormat };
