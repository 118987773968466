import { ProductTypeConnect } from 'src/components/Calculator/types';
import {
  EvoProductPlan,
  GuaranteedPartValues,
  OsSpecific,
  ProductEvolutionCloudServerData,
} from 'src/components/Calculator/types/evo';

/**
 * Адаптация имен OC для консоли.
 * НЕ адаптирует Astra!
 */
function osNamesAdapter(os: OsSpecific) {
  if (os === 'CentOS 7 kernel 5.4 v2') return 'centos-7-kernel-5.4-v.2';
  const osAdapt = os.replaceAll(' ', '-').toLowerCase();
  return osAdapt;
}

type Oversubscription = '1:3' | '1:1' | '1:10';

type EvolutionCloudServerToConsole = Pick<
  ProductEvolutionCloudServerData,
  'evs' | 'ramAmount' | 'vCpuCoreCount'
> & {
  productType: 'evolutionCloudServer';
  oversubscription: Oversubscription;
  vmCount: number;
  os: string;
  plan: EvoProductPlan;
};

type OversubscriptionMap = {
  [Key in GuaranteedPartValues]: Oversubscription;
};

function getGuaranteedPart(guaranteedPart: GuaranteedPartValues) {
  const oversubscriptionMap: OversubscriptionMap = {
    '10': '1:10',
    '30': '1:3',
    '100': '1:1',
  };

  return oversubscriptionMap[guaranteedPart];
}

function transformEvolutionCloudServerToConsole(
  productData: ProductEvolutionCloudServerData,
  freeTier: boolean,
): EvolutionCloudServerToConsole {
  const oversubscription = getGuaranteedPart(productData.guaranteedPart);

  return {
    productType: 'evolutionCloudServer',
    evs: productData.evs,
    os: osNamesAdapter(productData.os),
    oversubscription,
    ramAmount: productData.ramAmount,
    vCpuCoreCount: productData.vCpuCoreCount,
    vmCount: productData.productQuantity,
    plan: freeTier ? 'freeTier' : 'default',
  };
}

interface ProductToConsole {
  productType: ProductTypeConnect;
}

function transformProductToConsole(
  productType: ProductTypeConnect,
): ProductToConsole {
  return {
    productType:
      productType === 'evolutionStorageS3FreeTier'
        ? 'evolutionStorageS3'
        : productType,
  };
}

interface TransformEvolutionCloudServerDataToConsole {
  productType: 'evolutionCloudServer';
  productData: ProductEvolutionCloudServerData;
}

interface TransformAnyProductDataToConsole {
  productType: ProductTypeConnect;
  productData?: any;
}

type TransformProductDataToConsole =
  | TransformEvolutionCloudServerDataToConsole
  | TransformAnyProductDataToConsole;

/**
 * Функция для трансформации данных для connect
 */
function transformProductDataToConsole({
  productType,
  productData,
}: TransformProductDataToConsole) {
  if (
    productType === 'evolutionCloudServer' ||
    productType === 'evolutionCloudServerFreeTier'
  ) {
    // TODO: нужно доделать эту функцию для GPU или написать специально для GPU отдельную
    return transformEvolutionCloudServerToConsole(
      productData,
      productType === 'evolutionCloudServerFreeTier',
    );
  }
  return transformProductToConsole(productType);
}

export { transformProductDataToConsole };
